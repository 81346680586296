import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"

export default () => {

  return (
    <Layout>
      <SEO title="Home" />
      <section className="privacy-policy-banner background-overlay">
        <h1 className="banner-title">Privacy POLICY</h1>
      </section>

      <section className="privacy-policy-content">
        <p>
          Last revised on 1st March 2020
    </p>
        <p>Welcome to Yitnow’s Privacy Policy. Thank you for taking the time to read it.</p>
        <p>
          We appreciate that you trust us with your information and we intend to always keep that trust. This starts with making sure you understand the information we collect, why we collect it, how it is used and your choices regarding your information. This Policy describes our privacy practices in plain language, keeping legal and technical jargon to a minimum.
    </p>
        <div className="divider"></div>
        <h2>1. WHO WE ARE</h2>
        <p>
          Cordial Radar Limited,<br />
      The HQ Cowork,<br />
      2A Cheung Hing Industrial Building,<br />
      12P Smithfield Road,<br />
      Kennedy Town,<br />
      Hong Kong S.A.R.
    </p>
        <div className="divider"></div>
        <h2>2. WHERE THIS PRIVACY POLICY APPLIES</h2>
        <p>
          This Privacy Policy applies to websites, apps, events and other services operated by Cordial Radar Limited as part of the Yitnow brand. For simplicity, we refer to all of these as our “services” in this Privacy Policy. To make it extra clear, we’ve added links to this Privacy Policy on all applicable services.
    </p>
        <p>
          Some services may require their own unique privacy policy. If a particular service has its own privacy policy, then that policy — not this Privacy Policy — applies.
    </p>
        <div className="divider"></div>
        <h2>3. INFORMATION WE COLLECT</h2>
        <p>
          It goes without saying, we can’t help you develop meaningful connections without some information about you, such as basic profile details and the types of people you’d like to meet, and tags related to what interests you. We also collect information generated as you use our services, for example access logs, as well as information from third parties, like when you access our services through a social media account. If you want additional info, we go into more detail below.
    </p>
        <p><em>Information you Give us</em></p>
        <p>You choose to give us certain information when using our services. This includes:</p>
        <ul>
          <li>
            When you create an account, you provide us with at least your login credentials, as well as some basic details necessary for the service to work, such as your gender and date of birth.
      </li>
          <li>
            When you complete your profile, you can share with us additional information, such as photos. To add certain content, like pictures or videos, you may allow us to access your camera or photo album. By choosing to provide this information, you consent to our processing of that information.
      </li>
          <li>
            When you subscribe to a paid service or make a purchase directly from us (rather than through a platform such as iOS or Android), you provide us or our payment service provider with information, such as your debit or credit card number or other financial information.
      </li>
          <li>
            When you participate in surveys or focus groups, you give us your insights into our products and services, responses to our questions and testimonials.When you choose to participate in our promotions, events or contests, we collect the information that you use to register or enter.
      </li>
          <li>
            If you contact Cordial Radar Limited, we collect the information you give us during the interaction. Sometimes, we monitor or record these interactions for training purposes and to ensure a high quality of service.
      </li>
          <li>
            If you ask us to communicate with or otherwise process information of other people (for example, if you ask us to send an email on your behalf to one of your friends), we collect the information about others that you give us in order to complete your request.
      </li>
          <li>
            We retain and process your chats with other users as well as the content you publish, as part of the operation of the services.
      </li>
          <li>
            We retain all information you provide to us when you activate our services including your icebreaker message and tags.
      </li>
          <li>
            If you decide to run an advertising campaign through Yitnow we will retain and process all information you provide to us in this capacity, including but not necessarily limited to advertising message, tags associated with the advert, and bidding information.
      </li>
        </ul>
        <p><em>Information we Receive From Others</em></p>
        <p>
          In addition to the information you provide us directly, we receive information about you from others, including:
    </p>
        <ul>
          <li>
            Other Users: other users may provide information about you as they use our services. For instance, we may collect information about you from other users if they contact us about you.
      </li>
          <li>
            Social Media: You may be able to use your social media login (such as Facebook Login) to create and log into your Yitnow account. This saves you from having to remember yet another user name and password and allows you to share some information from your social media account with us
      </li>
          <li>
            Other Partners: We may receive info about you from our partners, for instance where Yitnow ads are published on a partner’s websites and platforms (in which case they may pass along details on a campaign’s success).
      </li>
        </ul>
        <p><em>Information Collected When you use our Services</em></p>
        <p>When you use our services, we collect information about which features you’ve used, how you’ve used them and the devices you use to access our services. See below for more details:</p>
        <ul>
          <li>
            Usage Information: We collect information about your activity on our services, for instance how you use them (e.g., date and time you logged in, features you’ve been using, searches, clicks and pages which have been shown to you, referring webpage address, advertising that you click on) and how you interact with other users (e.g., users you connect and interact with, time and date of your exchanges, number of messages you send and receive).
      </li>
          <li>
            We collect information from and about the device(s) you use to access our services, including:
        <ul>
              <li>
                hardware and software information such as IP address, device ID and type, device-specific and apps settings and characteristics, app crashes, advertising IDs (such as Google’s AAID and Apple’s IDFA, both of which are randomly generated numbers that you can reset by going into your device’ settings), browser type, version and language, operating system, time zones, identifiers associated with cookies or other technologies that may uniquely identify your device or browser (e.g., IMEI/UDID and MAC address);
          </li>
              <li>
                information on your wireless and mobile network connection, like your service provider and signal strength;
          </li>
              <li>
                information on device sensors such as accelerometers, gyroscopes and compasses.
          </li>
            </ul>
          </li>
          <li>Other Information With Your Consent: If you give us permission, we can collect your precise geolocation (latitude and longitude) through various means, depending on the service and device you’re using, including GPS, Bluetooth or Wi-Fi connections. This is done in order to provide you with the location dependent messaging and advertising features that Yitnow furnishes you with. The collection of your geolocation usually happens only when you activate the messaging feature or create an advertising campaign, however it may also occur in the background even when you aren’t using the services if the permission you gave us expressly permits such collection. If you decline permission for us to collect your geolocation, we will not collect it, however messaging and campaign building features of Yitnow cannot work without it. Similarly, if you consent, we may collect your photos and videos (for instance, if you want to publish a photo, video or streaming on the services).</li>
        </ul>
        <div className="divider"></div>
        <h2>4. COOKIES AND OTHER SIMILAR DATA COLLECTION TECHNOLOGIES</h2>
        <p>
          We may use and may allow others to use cookies and similar technologies (e.g., web beacons, pixels) to recognize you and/or your device(s).
    </p>
        <p>
          Some web browsers (including Safari, Internet Explorer, Firefox and Chrome) have a “Do Not Track” (“DNT”) feature that tells a website that a user does not want to have his or her online activity tracked. If a website that responds to a DNT signal receives a DNT signal, the browser can block that website from collecting certain information about the browser’s user. Not all browsers offer a DNT option and DNT signals are not yet uniform. For this reason, many applications, including Yitnow, do not currently respond to DNT signals.
    </p>
        <div className="divider"></div>
        <h2>5. HOW WE USE INFORMATION</h2>
        <p>
          The main reason we use your information is to deliver and improve our services. Additionally, we use your info to help keep you safe and to provide you with advertising that may be of interest to you. We also seek to use information gathered to prevent fraudulent usage of Yitnow including usage that would harm advertisers. Read on for a more detailed explanation of the various reasons we use your information, together with practical examples.
    </p>
        <p><em>To Administer Your Account and Provide our Services to you</em></p>
        <ul>
          <li>
            Create and manage your account
      </li>
          <li>
            Provide you with customer support and respond to your requests
      </li>
          <li>
            Complete your transactions
      </li>
          <li>
            Communicate with you about our services, including order management and billing
      </li>
        </ul>
        <p><em>To Help you Connect With Other Users</em></p>
        <ul>
          <li>The messaging services Yitnow provides are highly dependent on the correct datetime and location of the user. It is therefore important that we can collect the data that informs this.</li>
        </ul>
        <p><em>To Protect Users and Prevent Fraud</em></p>
        <ul>
          <li>
            The services Yitnow provides to its users are only possible because some users pay for advertising on the platform. Cordial Radar Limited will do everything we can to protect those users from bot fraud and other types of fraud which act to diminish the experience of all users on our platform and lead to unnecessary expenditure by advertisers.
      </li>
          <li>
            The data we collect is used to identify accounts whose use is malicious or fraudulent.
      </li>
          <li>
            Address ongoing or alleged behaviour which breaches our terms of service.
      </li>
          <li>
            Perform data analysis to better understand and design countermeasures against these activities.
      </li>
          <li>
            To identify users who have previously been banned from our service signing up under alternate accounts. These alternate accounts will ether be pre-blocked or banned after sign-up.
      </li>
        </ul>
        <p><em>To Serve you Relevant Offers and Advertisments</em></p>
        <ul>
          <li>
            A key aim of Yitnow is to provide its users with up to date information on the best places to meet up in your area. Yitnow does this by serving adverts from venue providers and other advertisers in your area, based on information you have provided us with.
      </li>
          <li>
            We may also contact you by email phone or social media or mobile device with information, correspontence or offers.
      </li>
        </ul>
        <p><em>To Improve our Services and Develop new Ones</em></p>
        <ul>
          <li>
            Administer focus groups and surveys
      </li>
          <li>
            Conduct research and analysis of users’ behaviour to improve our services and content (for instance, we may decide to change the look and feel or even substantially modify a given feature based on users’ behaviour)
      </li>
          <li>
            Develop new features and services (for example, we may decide to build a new interests-based feature further to requests received from users).
      </li>
        </ul>
        <p><em>To Ensure Legal Compliance</em></p>
        <ul>
          <li>
            Comply with legal requirements
      </li>
          <li>
            Assist law enforcement
      </li>
          <li>
            Enforce or exersise our rights, for example our Terms
      </li>
        </ul>
        <p>
          To process your information as described above, we rely on the following legal bases:
    </p>
        <ul>
          <li>
            Provide our service to you: Most of the time, the reason we process your information is to perform the contract that you have with us. For instance, as you go about using our service to find a place to meet people nearby right now and then meet them, we use your information to maintain your account and your profile, to make it viewable to other users and recommend other users to you.
      </li>
          <li>
            Legitimate interests: We may use your information where we have legitimate interests to do so. For instance, we analyze users’ behavior on our services to continuously improve our offerings, we suggest offers we think might interest you, and we process information for administrative, fraud detection and other legal purposes.
      </li>
          <li>
            Consent: From time to time, we may ask for your consent to use your information for certain specific reasons. You may withdraw your consent at any time by contacting us at the address provided at the end of this Privacy Policy.
      </li>
        </ul>
        <div className="divider"></div>
        <h2>6. HOW WE SHARE INFORMATION</h2>
        <p>
          Since our goal is to help you meet nearby people in real life (irl) and give you a space to do that, the main sharing of users’ information is, of course, with other users. We also share some users’ information with service providers and partners who assist us in operating the services, and in some cases, legal authorities. Read on for more details about how your information is shared with others.
    </p>
        <ul>
          <li>
            <em>With other users</em><br />
        You share information with other users when you voluntarily disclose information on the service. Please be careful with your information and make sure that the content you share is stuff that you’re comfortable being publically viewable since neither you nor we can control what others do with your information once you share it.
      </li>
          <li>
            <em>With our service providers and Partners</em><br />
        We use third parties to help us operate and improve our services. These third parties assist us with various tasks, including software development, maintanance, data hosting and maintenance, analytics, customer care, marketing, advertising, payment processing and security operations.
      </li>
          <li>
            <em>For corporate transactions</em><br />
        We may transfer your information if we are involved, whether in whole or in part, in a merger, sale, aquisition, divestiture, restructuring, reorganisation, dissolution, bankruptcy or other change of ownership or control.
      </li>
          <li>
            <em>When required by law</em><br />
        We may disclose your information if reasonably necessary:
        <ol>
              <li>
                to comply with a legal process, such as a court order, subpoena, or search warrant, government/ law enforcement investigation or other legal requirements;
          </li>
              <li>
                to assist in the prevention or detection of crime (subject in each case to applicable law);
          </li>
              <li>
                to protect the safety of any person.
          </li>
            </ol>
          </li>
          <li>
            <em>To enforce legal rights</em><br />
        We may also share information:
        <ol>
              <li>
                if disclosure would mitigate our liability in an actual or threatened lawsuit;
          </li>
              <li>
                as necessary to protect our legal rights and legal rights of our users, business partners or other interested parties;
          </li>
              <li>
                to enforce our agreements with you;
          </li>
              <li>
                to investigate, prevent or take other action regarding illegal activity, suspected fraud or other wrongdoing.
          </li>
            </ol>
          </li>
          <li>
            <em>With your consent or at your request</em><br />
        We may ask for your consent to share your information with third parties. In any such case, we will make it clear why we want to share the information. We may use and share non-personal information (meaning information that, by itself, does not identify who you are such as device information, general demographics, general behavioral data, geolocation in de-identified form), as well as personal information in hashed, non-human readable form, under any of the above circumstances. We may also share this information with third parties (notably advertisers) to develop and deliver targeted advertising on our services and on websites or applications of third parties, and to analyze and report on advertising you see. We may combine this information with additional non-personal information or personal information in hashed, non-human readable form collected from other sources.
      </li>
        </ul>
        <div className="divider"></div>
        <h2>7. CROSS-BORDER DATA TRANSFERS</h2>
        <p>
          Sharing of information laid out in Section 6 sometimes involves cross-border data transfers, for instance to the United States of America and other jurisdictions. As an example, where the service allows for users to be located in the European Economic Area (“EEA”), their personal information is transferred to countries outside of the EEA. We use standard contract clauses approved by the European Commission or other suitable safeguard to permit data transfers from the EEA to other countries. Standard contractual clauses are commitments between companies transferring personal data, binding them to protect the privacy and security of your data.
    </p>
        <div className="divider"></div>
        <h2>8. YOUR RIGHTS</h2>
        <p>
          We want you to be aware of your privacy rights. Here are a few key points to remember:
    </p>
        <ul>
          <li>
            <u>Reviewing your information.</u> Applicable privacy laws may give you the right to review the personal information we keep about you (depending on the jurisdiction, this may be called right of access, right of portability or variations of those terms). You can request a copy of your personal information by putting in such a request.
      </li>
          <li>
            <u>Updating your information.</u> If you believe that the information we hold about you is inaccurate or that we are no longer entitled to use it and want to request its rectification, deletion or object to its processing, please contact us.
      </li>
        </ul>
        <p>
          For your protection and the protection of all of our users, we may ask you to provide proof of identity before we can answer the above requests.
    </p>
        <p>
          Keep in mind, we may reject requests for certain reasons, including if the request is unlawful or if it may infringe on trade secrets or intellectual property or the privacy of another user. If you wish to receive information relating to another user, such as a copy of any messages you received from him or her through our service, the other user will have to contact our us to provide their written consent before the information is released.
    </p>
        <p>
          Also, we may not be able to accommodate certain requests to object to the processing of personal information, notably where such requests would not allow us to provide our service to you anymore. For instance, we cannot provide our service if we do not have your date of birth.
    </p>
        <ul>
          <li>
            <u>Uninstall.</u> You can stop all information collection by an app by uninstalling it using the standard uninstall process for your device. If you uninstall the app from your mobile device, the unique identifier associated with your device will continue to be stored. If you re-install the application on the same mobile device, we will be able to re-associate this identifier to your previous transactions and activities.
      </li>
          <li>
            <u>Accountability.</u> In certain countries, including in the European Union, you have a right to lodge a complaint with the appropriate data protection authority if you have concerns about how we process your personal information. The data protection authority you can lodge a complaint with notably may be that of your habitual residence, where you work or where we are established.
      </li>
        </ul>
        <div className="divider"></div>
        <h2>9. HOW LONG WE RETAIN YOUR INFORMATION</h2>
        <p>
          We keep your personal information only as long as we need it for legitimate business purposes (as laid out in Section 5) and as permitted by applicable law. To protect the safety and security of our users on and off our services, we implement a safety retention window of three months following account deletion. During this period, account information will be retained although the account will of course not be visible on the services anymore.
    </p>
        <p>
          In practice, we delete or anonymize your information upon deletion of your account (following the safety retention window) or after two years of continuous inactivity, unless:
    </p>
        <ol>
          <li>
            we must keep it to comply with applicable law (for instance, some “traffic data” is kept for one year to comply with statutory data retention obligations);
      </li>
          <li>
            we must keep it to evidence our compliance with applicable law (for instance, records of consents to our Terms, Privacy Policy and other similar consents are kept for five years);
      </li>
          <li>
            there is an outstanding issue, claim or dispute requiring us to keep the relevant information until it is resolved; or
      </li>
          <li>
            the information must be kept for our legitimate business interests, such as fraud prevention and enhancing users’ safety and security. For example, information may need to be kept to prevent a user who was banned for unsafe behavior or security incidents from opening a new account.
      </li>
        </ol>
        <p>
          Keep in mind that even though our systems are designed to carry out data deletion processes according to the above guidelines, we cannot promise that all data will be deleted within a specific timeframe due to technical constraints.
    </p>
        <div className="divider"></div>
        <h2>10. CHILDREN'S PRIVACY</h2>
        <p>
          Our services are restricted to users who are 18 years of age or older. We do not permit users under the age of 18 on our platform and we do not knowingly collect personal information from anyone under the age of 18. If you suspect that a user is under the age of 18, please use the reporting mechanism available through the service.
    </p>
        <div className="divider"></div>
        <h2>11. PRIVACY POLICY CHANGES</h2>
        <p>
          Because we’re always looking for new and innovative ways to help you build meaningful connections, this policy may change over time. We will notify you before any material changes take effect so that you have time to review the changes.
    </p>
        <div className="divider"></div>
        <h2>12. HOW TO CONTACT US</h2>
        <p>
          If you have questions about this Privacy Policy, reach us <a href="/contact/">here</a>.
    </p>
      </section>
    </Layout>
  )
};

